<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <table-navigation :table-title="tableTitle"
                        :table-buttons="tableButtons"
                        :table-columns="tableColumns"
                        :table-data="tableData"
                        :router-view="routerView"
                        :connection-error="connectionError"
                        :pagination="pagination"
                        :filter-options="filterOptions"
                        :filters="tableFilters"
                        @page="changePage"
                        @filters="changeFilters"
      >
      </table-navigation>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import TableNavigation from "@/views/Store/Tables/TableNavigation";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

const {mapState, mapActions} = createNamespacedHelpers('ProductListModule')

export default {
  name: 'ProductList',
  components: {
    BreadcrumbHeader,
    TableNavigation,
  },
  data() {
    return {
      tableTitle: 'Lista produktów',
      routerView: 'product-view',
      tableButtons: [
        {
          label: 'Dodaj produkt',
          icon: 'fas fa-edit',
          link: 'product-add',
        },
      ],
      tableColumns: [
        {
          prop: 'name',
          label: 'Nazwa',
          minWidth: 150,
          sortable: true,
          link: true,
        },
        {
          prop: 'category',
          label: 'Kategoria',
          minWidth: 80,
          sortable: true,
        },
        {
          prop: 'ean',
          label: 'EAN',
          minWidth: 80,
          sortable: true,
        },
        {
          prop: 'price',
          label: 'Cena',
          minWidth: 60,
          sortable: true,
        },
        {
          prop: 'active',
          label: 'Aktywny',
          minWidth: 60,
          sortable: false,
        },
      ],
      filterOptions: [
        {field: 'name', label: 'Nazwa'},
        {field: 'ean', label: 'EAN'},
        {field: 'active', label: 'Aktywny'},
      ],
    }
  },
  computed: {
    ...mapState({
      tableData: state => state.items,
      totalItems: state => state.totalItems,
      tableFilters: state => state.filterValues,
      connectionError: state => state.apiConnectionErr,
    }),
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Produkty'},
      ];
    },
    handleError() {
      return `Błąd ${this.connectionError.status} - ${this.$t[this.connectionError.status]}`;
    },
    pagination() {
      return {
        totalItems: this.totalItems,
        perPage: 20,
        currentPage: parseInt(this.$route.params.pageNum || 1),
      };
    },
  },
  methods: {
    changePage(page) {
      this.$router.push({name: 'product-list', params: {pageNum: page}});
    },
    changeFilters(filters) {
      this.$store.commit('ProductListModule/setFilterValues', filters);
      this.$store.dispatch('ProductListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: 1,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('ProductListModule/resetItems', []);
    return next();
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('ProductListModule/resetItems', []);
    this.$store.dispatch('ProductListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: to.params.pageNum || 1,
    });
    return next();
  },
  beforeCreate() {
    this.$store.dispatch('ProductListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: this.$route.params.pageNum || 1,
    });
  },
}
</script>
